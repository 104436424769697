import React from 'react';

import {
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";


export default function NavMenuItem(props) {


    return (
        <NavItem>
            <NavLink tag={Link} className="nav-menu__item" to={props.to}>
                {props.text}
            </NavLink>
        </NavItem>
    );
};



