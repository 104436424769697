import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/Store';
import './navigation-field-style.css';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { useLocation } from 'react-router-dom';
export default observer(function NavigationField() {
  const { contentStore, webpageStore } = useStore();
  const { getNavigationList, navigationList, setNavigationListState, navigationListIsOpen } = contentStore;
  const location = useLocation();
  
  useEffect(() => {
    getNavigationList();

  }, []);

  useEffect(()=>{
    if(navigationListIsOpen){
      setNavigationListState(false);
    }

  }, [location.hash]);

  useEffect(() => {
    setNavigationListState(false);
  },[webpageStore.lang]);



/* 
fixa så knappen följer menysträcketoch går nedåt istället. 
*/

  return (navigationList ? (
  <section className='nav-field__container'>
    
    <button
    onClick={() => { getNavigationList(); setNavigationListState(!navigationListIsOpen) }}
      className={`nav-field-burger-section ${navigationListIsOpen ? 'nav-field-burger-section--nav-open': ''}`}>
      <section className='nav-field__burger-btn'>
              </section>
    </button>
    <section
      className={`nav-field ${navigationListIsOpen ? 'nav-field--open': 'nav-field--closed'}`}>
      <Card 
        tag='article'
        className='nav-field-card'>
        <CardBody tag='section'>
      <ListGroup 
      className='nav-field__list-group'

      >
        {navigationList.map((item, index) =>
        (<ListGroupItem
          key = {index}
          action
          href={`/privacy-policy#${item.id}`}
          tag='a'
          className={`nav-field__item nav-field__${item.node.toLowerCase()}-item`}>

          {item.textContent}
        </ListGroupItem>))}
      </ListGroup>
      </CardBody>
      </Card>
    </section>

  </section>) : (null))
});
