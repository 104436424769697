import { makeAutoObservable } from "mobx";
import StoredAlfasafeLogo from '../../img/logo/2023_11_logo_alfasafe_claim_rgb.png';
import StoredSideGraphics from '../../img/background/double_bar.svg';


export default class WebpageStore {

    lang = localStorage.getItem("lang");
    langSelectCollapsed = true;

    AlfasafeLogo = StoredAlfasafeLogo;
    SideGraphics = StoredSideGraphics;

    loadingInitial = true;
    navbarCollapsed = true;
    entry = [];



    constructor(){
        makeAutoObservable(this);
    }

    getLang = () => {
        if(localStorage.getItem('lang') === null ){
            this.setLang("sv");
        }
        this.setLang(localStorage.getItem("lang"));
        this.setLoadingInitial(false);
    }
    getLangShort = () => {
        //Get current language [0] and language you can select [1]
        return this.lang === "sv" ? { currentLang: "sv", choiceLang: "en" } : { currentLang: "en", choiceLang: "sv" };
    }

    setLang = async (langValue) => {
        localStorage.setItem('lang', langValue);
        this.lang = langValue;


      


    }
    toggleLang = () => {
        let langValue = this.lang === 'sv' ? 'en-US' : 'sv';
        localStorage.setItem('lang', langValue);
        this.lang = langValue;


        if (!this.langSelectCollapsed) {
            this.toggleLanguageSelect();
        }
    }

    toggleLanguageSelect = () => {
        this.langSelectClassString = this.langSelectCollapsed ? "navbar__lang-select navbar__lang-select--shown navbar__lang-select--" + this.getLangShort().currentLang : "navbar__lang-select navbar__lang-select--hidden navbar__lang-select--" + this.getLangShort().currentLang;
        this.langSelectCollapsed = !this.langSelectCollapsed;
    }

    setLoadingInitial = (state) => {
        this.loadingInitial = state;
    }


    toggleNavbarCollapsed = () => {
        this.navbarCollapsed = !this.navbarCollapsed;
    }
    closeNavbar = () => {
        var burger = document.querySelector("#burger");

        if (burger.checked)
        {
            burger.checked = false;
            this.toggleNavbarCollapsed();

        }
    }


}