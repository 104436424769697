import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/Store';
import { Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import './content-page-style.css';
import RenderRichText from '../../api/RenderRichText';
import { toJS } from 'mobx';
import LandingPage from '../LandingPage/LandingPage';
import PrivacyPolicyPage from '../PrivacyPolicyPage/PrivacyPolicyPage';
import ContactForm from '../../components/ContactForm/ContactForm';
import NavigationField from '../../components/NavigationField/NavigationField';

export default observer(function ContentPage(props) {
	const { webpageStore, contentStore, contactFormStore } = useStore();
	const { entry } = contentStore;
	const { tag, id } = props;
	const location = useLocation();


	useEffect(() => {
		contentStore.getPageContentById(id, webpageStore.lang);
	}, [webpageStore.lang, location]);





	if (contentStore.loadingInitial) {
		return (<></>);
	}
	else
		switch (tag) {
			case 'landingPage':
				return (<LandingPage>

					<h1 
					className='landing-page__header'>
						{entry.fields.header}
					</h1>
					<p 
					className='landing-page__sub-header'>
						{entry.fields.firstPageContent}
					</p>
					<h2 
					onClick={()=> contactFormStore.toggleContactForm()} 
					className='landing-page__call-to-action-part'> 
					
						<span className='landing-page__call-to-action-arrow'></span><span className='landing-page__call-to-action-arrow'></span> {entry.fields.callToAction}
					</h2>
					<ContactForm />
      				
					

				</LandingPage>);
			case 'privacyPolicy':
				return (
					<Container className='content-page__wrapper'>
					{entry.fields.isNavigatable ? (<NavigationField />) : null}
					<PrivacyPolicyPage>
						
						<RenderRichText 
						content={toJS(entry.fields.pageContent.content)} 
						divider={[
							{ nodeType: 'heading-1', className: 'privacy-policy-header', prefDividerEl: 'section' }, 
							{ nodeType: 'heading-2', className: 'privacy-policy__content-block', prefDividerEl: 'section' }]} 
							hasNavigationField={entry.fields.isNavigatable}
						/>
					</PrivacyPolicyPage>
					</Container>);
			default:
				return (
					<Container className='content-page__wrapper'>
						<RenderRichText 
						content={toJS(entry.fields.pageContent.content)} 
						divider={[
							{ nodeType: 'heading-1', className: 'content-page__header', prefDividerEl: 'section' }, 
							{ nodeType: 'heading-2', className: 'content-page__content-block', prefDividerEl: 'section' }]} />
					</Container>);


		}



});