import React from 'react';
import { generateSection, setNavigationField } from './renderText';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function RenderRichText(props) {
  let content = props.content;
  const divider = props.divider;
  const hasNavigationField = props.hasNavigationField;
  let entirePageArray = [];
  //Jag vill loopa igenom alla element, rigga upp de i sektioner och sen spotta ut allt på sidan när det är genomfört
  if (props.content === 0) {
    return null;
  }
  if (props.content.length > 1) {
    try {

      while (content.length > 0) {
        let currentSection = content.slice(1);
        let lastIndex = currentSection.findIndex(x => divider.some(d => d.nodeType === x.nodeType));
        if (lastIndex === -1) {
          entirePageArray.push([divider.find(x => x.nodeType === content[0].nodeType), ...content]);
          content = [];

        }
        else {

          let newSlice = content.slice(0, lastIndex + 1);
          entirePageArray.push([divider.find(x => x.nodeType === content[0].nodeType), ...newSlice]);
          content = content.slice(lastIndex + 1);

        }

      }
    } catch (e) {
      entirePageArray = props.content;
    }
  }




  return (<>{entirePageArray.map((part, index) => generateSection(part, hasNavigationField, index))}</>);



};

