/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './Layout';
import { useStore } from '../stores/Store';
import { observer } from 'mobx-react-lite';
import CookiesPopup from '../components/CookiesPopup/CookiesPopup';
function App(){
  
    const { webpageStore } = useStore();
    const location = useLocation();


    useEffect(() => {
        webpageStore.getLang();
    }, [webpageStore.lang]);

    //Page scrolls up every time you enter new page, remove if it causes problems in the future
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


  return (
      <Layout>
          <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
      <CookiesPopup lang={webpageStore.lang} />
    </Layout>
  );
}

export default observer(App);