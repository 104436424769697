/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { observer } from 'mobx-react-lite';
import './landing-page-style.css';

export default observer(function LandingPage({children}) {
  
  return (<>
    <section className="hero">
      {children}
    </section></>);

});

