import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Collapse,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import './nav-menu-style.css';


import {useStore} from '../../stores/Store';
import NavMenuItem from './NavMenuItem';


export default observer(function NavMenu(){

    const { webpageStore, contentStore } = useStore();
    const location = useLocation();
    useEffect(() => {
        webpageStore.closeNavbar();
        if (!webpageStore.langSelectCollapsed) {
            webpageStore.toggleLanguageSelect();
        }
    }, [webpageStore.lang, location]);

    useEffect(() => {
      contentStore.getMenuPageNames(webpageStore.lang);
    }, [webpageStore.lang])

  return (
      <header className="header ">



          <section className="sidebar-container">

              <img src={webpageStore.SideGraphics} alt="bars at the side of the page" className="sidebar-container__graphics" />
          </section>

          <nav className="navbar-expand-md navbar-toggleable-md navbar p-0" >
              <section className="container-fluid align-items-md-end">
              <NavbarBrand tag={Link} to='/' >
          <img alt="Alfasafe logo" src={webpageStore.AlfasafeLogo} className="navbar__logo" />
              </NavbarBrand>

        <form className="burger-section">
              <input
                  type="checkbox"
                  className="burger"
                  id="burger"
                  onClick={() => { webpageStore.toggleNavbarCollapsed() }}
                  aria-label='hamburger'
              />

        <label className='burger-btn' htmlFor='burger'>
          <span></span>
        </label>
              </form>



              <Collapse
                  className="d-md-inline-flex flex-sm-row-reverse align-items-end"
                  isOpen={!webpageStore.navbarCollapsed}
                  navbar>
                  
                  <ul className="navbar-nav flex-grow align-items-md-center">

                  {contentStore.menuLoadingInitial ? (<>

                  
                  </>) : (<>

                  {contentStore.mainMenuOptions.items.map((menuOption, index) => (
                  <NavMenuItem key={index} to={menuOption.fields.url} text={menuOption.fields.menuName.toUpperCase()} />

                  ))}

                      </>)}
                      <NavItem className="nav-item__langs">
                          <section className="navbar__lang-icon-placement">
                                  <NavLink className="navbar__lang-icon navbar__lang-icon--en" onClick={() => { webpageStore.setLang('en-US') }} />
                                  <NavLink className="navbar__lang-icon navbar__lang-icon--sv" onClick={() => { webpageStore.setLang('sv') }} />
                           </section>
            </NavItem>
          </ul>
              </Collapse>
</section>
      </nav>
      <hr />
    </header>
  );
});

