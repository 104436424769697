import { toJS } from 'mobx';

// Data som kommer in =  Object ligger på entry.fields.pageContent.content

// Vilken nodtyp är det? = entry.fields.pageContent.content[X].nodeType
/*
heading-1 = h1
heading-2 = h2
heading-3 = h3
heading-4 = h4
heading-5 = h5
heading-6 = h6
paragraph = p
unordered-list = ul
ordered-list = ul
list-item = li
Var finns värdet / texten? = entry.fields.pageContent.content[X].content[Y].value


Om det är en unordered list: 
  entry.fields.pageContent.content[X].nodeType = unordered-list
  entry.fields.pageContent.content[X].content[Y].content['0'].content['0'].value


{[{nodeType:'heading-1', className:'content-page-header',prefferedHTMLElemenent:'section'}]}
*/

// const nodeTypes = {
//     'heading-1':'h1',
//     'heading-2':'h2',
//     'heading-3':'h3',
//     'heading-4':'h4',
//     'heading-5':'h5',
//     'heading-6':'h6',
//     'paragraph':'p',
//     'unordered-list':'ul',
//     'ordered-list':'ol',
//     'list-item':'li',
//     'hyperlink':'a'};

/**
 * 
 * @param {string} word generates a random character if there is a space, line or swedish characters.
 * @returns 
 */
function generateCssId(word){
    let returnValue = '';
    for (let i = 0; i < word.length; i++) {
        if(/[_ ]/.test(word[i])){
            returnValue += '-';
        }
        else if(/[1234567890]/.test(word[i])){
            returnValue += 'd';
        }
        else if(/[åä]/.test(word[i])){
            returnValue += 'a';
        }
        else if(/[ö]/.test(word[i])){
            returnValue += 'o';
        }
        else{
            returnValue += word[i];
        }
        
    }
    return returnValue;
}

/**
   * 
   * Renders text from RichTextBody by checking the marks on the value (bold, italic and underline), and recursing itself into the correct 
   * tags.
   * 
   * @param {[{type:string}] | []} marks 
   * Array with markings (bold, italic or underline), specific for a part of the paragraph.
   * @param {string} content 
   * The content that is associated to the marks shown above.
   * @returns the content with the correct markings
   */
export function renderText(marks = [], content, key) {

    let currentMark = marks;
    if (marks.length === 0) {
        return (<span key={key}>{content}</span>);
    }
    else {

        let result = (<>{content}</>);
        if (marks.length > 1) {

            currentMark = marks.shift();
            //console.log(`${currentMark} content: ${content}`);
            result = renderText(marks, result, key);

        }

        if (marks[0].type === 'bold') {
            return (<strong key={key}>{result}</strong>);
        }
        else if (marks[0].type === 'italic') {
            return (<i key={key}>{result}</i>);
        }
        else if (marks[0].type === 'underline') {
            return (<u key={key}>{result}</u>);
        }
        else {
            return (<span key={key}>{result}</span>);
        }
    }
}

/**
 * 
 * @param {Array} section 
 * @returns 
 */
export function generateSection(section, hasNavigationField, key) {
    let headerId = '';
    try {
        if (Object.hasOwn(section[0], 'className')) {
            let newValues = section.slice(1);


            if (section[0].prefDividerEl === 'section') {
                return (<section key={key} className={section[0].className}>{newValues.map((subSection, index) => generateSection(subSection, hasNavigationField, index))}</section>);
            }
        }

    } catch { };
    
    switch (section.nodeType) {
        case 'heading-1':
            headerId = generateCssId(section.content[0].value.toLowerCase());

            return (<h1 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : ''}>{section.content.map((subSection, index) => {
                return renderText(toJS(subSection.marks), subSection.value, index)
            })}</h1>);    
            

        case 'heading-2':
            headerId = generateCssId(section.content[0].value.toLowerCase());
            
            return (
                <h2 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : null}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</h2>);
        case 'heading-3':
            headerId = generateCssId(section.content[0].value.toLowerCase());

            return (
                <h3 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : null}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</h3>);
        case 'heading-4':
            headerId = generateCssId(section.content[0].value.toLowerCase());

            return (
                <h4 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : null}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</h4>);
        case 'heading-5':
            headerId = generateCssId(section.content[0].value.toLowerCase());

            return (
                <h5 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : null}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</h5>);
        case 'heading-6':
            headerId = generateCssId(section.content[0].value.toLowerCase());

            return (
                <h6 key={key} className='content-anchor' id={hasNavigationField ? `navField${headerId}` : null}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</h6>);
        case 'hyperlink':
            return (<a key={key} href={section.data.uri}>{section.content['0'].value}</a>);
        case 'paragraph':
            if (section.content.some(subSection => subSection.nodeType === 'hyperlink')) {
                const hyperSection = section.content.findIndex(subSection => subSection.nodeType === 'hyperlink');
                return (<p key={key}>
                    {section.content.map((subSection, index) => index !== hyperSection ?
                        (renderText(toJS(subSection.marks), subSection.value, index)) :
                        (<a key={key} href={subSection.data.uri}>{renderText(toJS(subSection.content['0'].marks), subSection.content['0'].value)}</a>))}
                </p>);

            }
            return (<p key={key}>{section.content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</p>)

        case 'unordered-list':
            return (<ul key={key}>
                {section.content.map((listItem, index) => generateSection(listItem, hasNavigationField, index))}
            </ul>);
        case 'ordered-list':
            return (<ol key={key}>
                {section.content.map((listItem, index) => generateSection(listItem, hasNavigationField, index))}
            </ol>);
        case 'list-item':

            return (<li key={key}> {section.content['0'].content.map((subSection, index) => renderText(toJS(subSection.marks), subSection.value, index))}</li>)
        default:
            return (<p key={key}></p>);
    }
}

export function setNavigationField(fields) {
    console.log(fields);
}