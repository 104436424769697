
import SignInPage from "../pages/SingInPage/SignInPage";
import ContentPage from "../pages/ContentPage/ContentPage";
import LandingPage from "../pages/LandingPage/LandingPage";

const AppRoutes = [
{
    index: true,
        element: <ContentPage tag='landingPage' id='umR95Bny1wndr8guURVEV' />

    },{
        path: '/privacy-policy',
        element: <ContentPage tag='privacyPolicy' id='1U1miBmQ8rK9UX747JNhGG' />
    }
    //{
    //    path: '/test-page',
    //    element: <TestPage />
    //},

];


export default AppRoutes;
