import React from 'react';
import './privacy-policy-style.css';
import { Container } from 'reactstrap';

const PrivacyPolicyPage = ({children}) => {
  return(
      <Container className={`privacy-policy`}>
        {children}
        </Container>);
};

export default PrivacyPolicyPage;
