import React, { useRef } from 'react'
import './contact-form-style.css';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';
import { FormGroup, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Spinner, Alert } from 'reactstrap';
import { useStore } from '../../stores/Store';
import * as Yup from 'yup';
import ContactFormInput from '../../common/contactForm/ContactFormInput';
export default observer(function ContactForm() {

  //Vad behöver fixas?

  /**
   * Kontaktformuläret så det fungerar som Udemy kursen jag gått innan
   *  					backBtn={() => contactFormStore.toggleContactForm()} 
            isActive={contactFormStore.isVisible} 
            loadingInitial={contactFormStore.loadingInitial} 
            setLoadingInitial={contactFormStore.loadingInitial}
   * 
   * */


  const formValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    content: '',
    maidenName:''

  }
  const store = useStore();
  const { contactFormStore, contentStore } = store;
  const { sendMail, isVisible, loadingInitial, toggleContactForm, alertIsVisible, setAlertVisibility, hasMetAProblem, setSuccessfullySentMail, successfullySentMail } = contactFormStore;
  const { contactFormAlertMessage, contactFormValues } = contentStore;
  const activeClass = isVisible ? 'active' : 'inactive';


  const formikRef = useRef();

  const validationSchema = Yup.object({
    firstName: Yup.string().required(localStorage.getItem("lang") === 'sv' ? 'Fyll i ert förnamn' : 'Please type down your lirst name'),
    lastName: Yup.string().required(localStorage.getItem("lang") === 'sv' ? 'Fyll i ert efternamn' : 'Please type down your last name'),
    email: Yup.string().email('Mail adressen är inte korrekt inskriven').required(localStorage.getItem("lang") === 'sv' ? 'Fyll i er mailadress' : 'Please type down your mail address'),
    content: Yup.string().required(localStorage.getItem("lang") === 'sv' ? 'Lämna ett meddelande som vi kan svara på' : 'Please type down a message that we can respond to')
  })

  const handleSubmit = async (values, resetForm) => {
    setAlertVisibility(false);
    const result = await sendMail(values);
    if (result) {
      resetForm();
      setSuccessfullySentMail(true);
    }

  }




  return (
    <section
      className={'contact__wrapper contact__wrapper--' + activeClass}
      onClick={(e) => {
        if (!e.target.classList.contains('alert')) { setAlertVisibility(false) }
        if (e.target.classList.contains('contact__wrapper') && !loadingInitial) {
          toggleContactForm();
          formikRef.current?.resetForm();
          setAlertVisibility(false);
        }
      }} >



      {hasMetAProblem ? (
        <Alert tag='article' className='contact__alert-message' isOpen={alertIsVisible} color={contactFormAlertMessage.errorColor} toggle={() => setAlertVisibility(false)} >
          <h4 className='contact__alert-header'>{contactFormAlertMessage.errorMessageHeader}</h4>
          <hr />
          <p className='contact__alert-content'>{contactFormAlertMessage.errorMessageContent}</p>
        </Alert>
      ) : (
        <Alert tag='article' className='contact__alert-message' isOpen={alertIsVisible} color={contactFormAlertMessage.primaryColor} toggle={() => {toggleContactForm(); formikRef.current?.resetForm(); setAlertVisibility(false); }} >
          <h4>{contactFormAlertMessage.successMessageHeader}</h4>
          <hr />
          <p>{contactFormAlertMessage.successMessageContent}</p>
        </Alert>
      )}
      {contactFormValues ? (<>
        <Card tag='article' className={'contact__card contact__card--' + activeClass + (loadingInitial ? ' contact__card--loading' : ' ')}>


          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={formValues}
            onSubmit={(values, { resetForm }) => { handleSubmit(values, resetForm) }}
            validationSchema={validationSchema}>
            {({ handleSubmit }) => (
              <CardBody tag='section'>
                <CardTitle tag='h2'>{contactFormValues.header}</CardTitle>
                <CardSubtitle tag='p'>{contactFormValues.subtitle}</CardSubtitle>

                <Form className='contact__form' onSubmit={handleSubmit}>
                  <Row tag='section' xs='1' md='2'>
                    <Col tag='section'>
                      <ContactFormInput disabled={loadingInitial} name='firstName' type='text' placeholder={`${contactFormValues.fNamePlaceHolder}*`} label={contactFormValues.fNamePlaceHolder} />
                    </Col>
                    <Col tag='section'>
                      <ContactFormInput disabled={loadingInitial} name='lastName' type='text' placeholder={`${contactFormValues.lNamePlaceHolder}*`} label={contactFormValues.lNamePlaceHolder} />

                    </Col>
                  </Row>
                  <Row tag='section'>
                    <ContactFormInput disabled={loadingInitial} name='email' type='email' placeholder={`${contactFormValues.mailPlaceHolder}*`} label={contactFormValues.mailPlaceHolder} />
                  </Row>
                  <Row tag='section' className='testing-area'>
                    <ContactFormInput disabled={loadingInitial} name='maidenName' type='text' placeholder='Maiden name' label='Maiden Name' />
                  </Row>
                  <Row tag='section'>
                    <ContactFormInput disabled={loadingInitial} name='phone' type='tel' placeholder={contactFormValues.phonePlaceHolder} label={contactFormValues.phonePlaceHolder} />
                  </Row>
                  <Row tag='section'>
                    <FormGroup tag='section'>
                      <ContactFormInput
                        disabled={loadingInitial}
                        name='content'
                        type='textarea'
                        placeholder={`${contactFormValues.contentPlaceHolder}*`}
                        label={contactFormValues.contentPlaceHolder}
                      />
                    </FormGroup>
                  </Row>

                  <Row tag='section'>
                  <Col tag='section'>
                      <Button disabled={loadingInitial || successfullySentMail} outline color='danger' className='contact__submit-btn' type='button' onClick={() => { toggleContactForm(); formikRef.current?.resetForm(); setAlertVisibility(false) }}>{contactFormValues.goBackButton}</Button>
                    </Col>

                    <Col tag='section'>
                      <Button disabled={loadingInitial || successfullySentMail} outline color='primary' className='contact__submit-btn' type='submit' onClick={handleSubmit}>{contactFormValues.sendButton}</Button>
                    </Col>

                  </Row>
                </Form>

              </CardBody>
            )}
          </Formik>



        </Card >
        <Spinner className={loadingInitial ? 'spinner-border--visible' : 'spinner-border--hidden'} color='primary' />
      </>) : null}

    </section>

  );
});
