import { makeAutoObservable} from "mobx";
import agent from "../api/agent";

export default class ContactFormStore {

    loadingInitial = false;
    isVisible = false;
    alertIsVisible = false;    
    hasMetAProblem = false;
    successfullySentMail = false;

    constructor(){
        makeAutoObservable(this);
    }



    setLoadingInitial = (state) => {
        this.loadingInitial = state;
    };
    

    toggleContactForm = () => {
        this.isVisible = !this.isVisible;

    }

    setHasAProblem = (state) => {
        this.hasMetAProblem = state;
    }

    setSuccessfullySentMail = (state) => {
        this.successfullySentMail = state;
    }

    setAlertVisibility = (state) => {
        this.alertIsVisible = state;

    }

    sendMail = async (formValues) => {

        this.setLoadingInitial(true);
        const response = await agent.Mail.send(formValues).then(res => {
            this.setHasAProblem(false);
            // console.log(res);
            return res;
        }).catch(e => {
            // console.error(e);        
            this.setHasAProblem(true);


        });
        this.setAlertVisibility(true);
        this.setLoadingInitial(false);
        return response;
    }



}
