
import NavMenu from "../components/NavMenu/NavMenu";
import Footer from "../components/Footer/Footer";

import React from "react";

const Layout = (props) => {
  return (
      <div className="wrapper">

      <NavMenu />
      <main className="main">
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;