import { makeAutoObservable, runInAction } from "mobx";
import { client } from '../api/contentClient';


/*
Vad behöver jag? 

- value
- nodeType
- marks

Exempel: 
[0] = value: 'Privacy Policy'               -- subSection.content['0'].value
[0] = nodeType: 'heading-1'                 -- subSection.nodeType
[0] = marks: []                             -- subSection.content['0'].marks

[X] = value: ''                             -- subSection.content['0'].value does not exist
[X] = nodeType: 'unordered-list'            -- subSection.nodeType
[X] = marks: []                             -- subSection.content['0'].marks does not exist
[X] = listItems: []                         -- subSection.content['i']

listItem=
[0] = value: 'textsträng'                   -- subSection.content['i'].content['0'].content['0'].value
[0] = nodeType: 'heading-1'                 -- subSection.content['i'].nodeType
[0] = marks: []                             -- subSection.content['i'].content['0'].content['0'].marks



*/

//Tanke att dela p� content och resterande info som store, s� man inte f�r allt i ett kluster.
export default class ContentStore {
    loadingInitial = true;
    menuLoadingInitial = true;
    navbarCollapsed = true;
    mainMenuOptions = [];
    entry = [];
    cookieData = null;
    contentType = '';
    navigationList = [];
    navigationListIsOpen = false;


    contactFormAlertMessage = {
        errorMessageHeader: '',
        successMessageHeader: '', 
        errorMessageContent: '', 
        successMessageContent: '', 
        color:'primary'
    };


    contactFormValues = {
        header: '',
        contentPlaceHolder:'',
        fNamePlaceHolder:'',
        goBackButton:'',
        lNamePlaceHolder:'',
        mailPlaceHolder:'',
        phonePlaceHolder:'',
        sendButton:'',
        subtitle:''};

    constructor(){
        makeAutoObservable(this);
    }



    setContactFormValues = (values) => {
        this.contactFormValues = {
            header: values.header,
            contentPlaceHolder:values.contentPlaceHolder,
            fNamePlaceHolder:values.fNamePlaceHolder,
            goBackButton:values.goBackButton,
            lNamePlaceHolder:values.lNamePlaceHolder,
            mailPlaceHolder:values.mailPlaceHolder,
            phonePlaceHolder:values.phonePlaceHolder,
            sendButton:values.sendButton,
            subtitle:values.subtitle};

        this.contactFormAlertMessage = {
            errorMessageHeader: values.errorMessageHeader,
            successMessageHeader: values.successMessageHeader, 
            errorMessageContent: values.errorMessageContent, 
            successMessageContent: values.successMessageContent, 
            primaryColor: values.primaryColor,
            errorColor: values.errorColor};
    }

    setLoadingInitial = (state) => {
        this.loadingInitial = state;
    }
    setMenuLoadingInitial = (state) => {
        this.menuLoadingInitial = state;
    }
    getPageContentById = async (id, lang) => {
        if(!this.entry){
            this.setLoadingInitial(true);
        }

        try {
            const entry = await client.getEntry(id, { locale: lang });


            const cookieData = await client.getEntry('JgWxTiAhYudthIuwNM7JI', { locale: lang });



            runInAction(() => {

                this.entry = entry;
                //console.log(entry.fields.formValues)
                this.contentType = entry.sys.contentType.sys.id;
                this.cookieData = cookieData;
    
                if(entry.sys.contentType.sys.id === 'landingPage'){
                    this.setContactFormValues(entry.fields.formValues);
                }
            });


        }catch (error){
            console.log(error);
        }
        if (this.entry !== undefined) {
            this.setLoadingInitial(false);

        }


    }
    getPageContentByTag = async (tag, lang) => {
        this.setLoadingInitial(true);
        try {
            let entries = await client.getEntry('5aLt8Bl6ZJhotOlU2nE5bc', { locale: lang })
                .then((response) => {
                    return response;
                });
            runInAction(() => {
                this.entry = entries;
                this.contentType = entries.sys.contentType.sys.id;
            });


        } catch (error) {
            console.log(error);
        }
        if (this.entry !== undefined) {
            this.setLoadingInitial(false);
        }


    }
    getMenuPageNames = async (lang) => {
        if(!this.mainMenuOptions){
            this.setMenuLoadingInitial(true);
        }
        try {
            let entries = await client.getEntries({'content_type':'contentPage', locale: lang })
                .then((response) => {
                    // console.log(toJS(response));
                    return response;
                });
            runInAction(() => {
                this.mainMenuOptions = entries;
            });


        } catch (error) {
            console.log(error);
        }
        if (this.mainMenuOptions !== undefined) {
            this.setMenuLoadingInitial(false);

        }

    }

    setNavigationListState = (state) => {

        this.navigationListIsOpen = state;

    }

    getNavigationList = async() => {
        let returnValue = [];
        const list = await document.querySelectorAll('main h1, main h2, main h3, main h4, main h5');
        list.forEach(item => {
            returnValue.push({id:item.id, textContent:item.textContent, node: item.nodeName});
        });
        runInAction(()=>{
            this.navigationList = returnValue;
        });
        
    }


    

}