import React from 'react';
import './footer-style.css';

const Footer = () => {
  return (
    <footer className="footer">
      <hr />  

      <section className="footer__information">
              <p className="footer__information-line">ALFASAFE AB <a href="/" className=" footer__information--span f-inter-footer"> WWW.ALFASAFE.SE</a></p>
              <p className=" footer__information--dash">/</p>
              <p className="footer__information-line">MAIL <a href='mailto:info@alfasafe.se' className=" footer__information--span f-inter-footer"> INFO@ALFASAFE.SE</a></p>
        {/* <p className="f_inter-footer footer__information--dash">/</p>
        <p className="f_inter-footer-bold">MOBILE<span className=" footer__information--span f_inter-footer"> +46 73 064 51 48</span></p> */}
      </section>
        
    </footer>
  );
}

export default Footer;