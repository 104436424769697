import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'api';

axios.interceptors.response.use(async response => {
 try {
   return response
 } catch (error) {
   console.log(error)
   return await Promise.reject(error)
 }
})

const responseBody = response => response.data

const requests = {
    get: url => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
//   put: (url, body) => axios.put(url, body).then(responseBody),
//   del: url => axios.delete(url).then(responseBody)
}



const Mail = {
    test: () => requests.get('/mail'),
    send: (body) => requests.post('/mail', body)
}

const agent = {
    Mail
}



export default agent;


