import { useField } from 'formik';
import React from 'react';
import { FormText, FormGroup, Input } from 'reactstrap';

function ContactFormInput (props) {
    const [field, meta] = useField(props.name);
    const size = props.type === 'textarea' ? 6 : 0;

  return (
    <FormGroup tag='section'>
        <label>{props.label}</label>
        <Input aria-label={props.label} style={{resize:'none'}} disabled={props.disabled} invalid={meta.touched && !!meta.error} {...field} {...props} rows={size}></Input>
        {meta.touched && meta.error ? (
            <FormText color='danger' style={{textAlign:'left'}} >{meta.error}</FormText> 
        ) : null}

              </FormGroup>
  );
}

export default ContactFormInput;

// <ErrorMessage name='fName' render={error => <Alert style={{padding:'.25rem 0 .25rem .5rem', margin:'0', textAlign:'left'}} color='danger' children={error} />}/>
//                 <Field className='form-control' required disabled={loadingInitial} name='fName' id='formFirstName' placeholder={textValues.fNamePlaceHolder} type='text'/>