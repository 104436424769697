import React from 'react';
import './cookies-style.css';
import { Button, Container, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import CookieImg from '../../../img/icons/cookieIcon.svg';
import { useStore } from '../../stores/Store';
import { observer } from 'mobx-react-lite';
import RenderRichText from '../../api/RenderRichText';

export default observer(function CookiesPopup () {

  
  const {contentStore, contactFormStore} = useStore();
  const {cookieData} = contentStore;
  return (
    <Container tag='section' className={contactFormStore.isVisible ? 'cookie-popup cookie-popup--contact-form-active' : 'cookie-popup'}>
    {cookieData ? (
      <>    
        <Button 
        className='cookie-popup__button'
        id='PopoverClick'
        type='img'
        color='none'
        ><img className='cookie-popup__icon' src={CookieImg} alt='Cookie image' /></Button>
        <UncontrolledPopover

        placement='left'
        target='PopoverClick'
        trigger='legacy'>

          <PopoverHeader>{cookieData.fields.header}</PopoverHeader>
          <PopoverBody>
            <RenderRichText content={cookieData.fields.message.content} />
            </PopoverBody>

        </UncontrolledPopover>
    </>
    ) : null}
        </Container>
        
  );
});