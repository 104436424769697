import { createContext, useContext } from "react";
import WebpageStore from './WebpageStore';
import ContentStore from "./ContentStore";
import ContactFormStore from "./ContactFormStore";

export const store = {
    webpageStore: new WebpageStore(),
    contentStore: new ContentStore(),
    contactFormStore: new ContactFormStore()

}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}